import { useState, useEffect } from 'react';
import '../styles/App.css';
import { User, Room } from '../utils/query';
import { useNavigate } from 'react-router-dom'
import { Box, Button, TextField, Typography, Avatar, IconButton } from '@mui/material';
import ErrorSnackbar from '../components/ErrorSnackbar';
import WordLine from '../components/WordLine';
import RoomSearch from '../components/RoomSearch';
import LogoutIcon from '@mui/icons-material/Logout';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';

export default function Main() {
  let navigate = useNavigate();
  let [userName, setUserName] = useState('');
  let [token, setToken] = useState('');
  let [error, setError] = useState('');
  const [resultWord, setResultWord] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    User.isLoggedIn().then((res) => {
      if (!res) {
        navigate('/login');
      }
    });
    User.info().then((res) => {
      setUserName(res.name);
      setResultWord(Array.from(res.name).map(() => Math.floor(Math.random() * 3)));
    }).catch(() => { navigate('/login'); });
  }, [navigate]);

  function handleJoin() {
    Room.info(token).then(async (room) => {
      navigate(`/room/${token}`);
    }).catch((err) => {
      setError(err.message);
    });
  };

  return (
    <Box>
      <IconButton onClick={() => User.logout()} sx={{ position: "absolute", right: "4px", top: "4px" }} aria-label="logout" size="medium">
        <LogoutIcon fontSize="inherit" />
      </IconButton>
      <RoomSearch open={openSearch} handleClose={() => setOpenSearch(false)} />
      <Box
        sx={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ErrorSnackbar text={error} openAlert={error !== ''} handleCloseAlert={() => setError('')} />
        <Avatar src="logo-1024.png" sx={{ alignSelf: 'center', mb: 2 }} />
        <Typography component={"h1"} variant="h4" gutterBottom textAlign="center" fontFamily={"monospace"}>Welcome to Wordy</Typography>
        <WordLine sx={{ maxWidth: Math.min(userName.length * 80, 500) }} currentlyTyping={false} isLast={true} word={userName.toUpperCase()} results={resultWord} />
        <Box maxWidth={500} mt={2}>
          <TextField autoComplete="off" fullWidth id="submitRoom" value={token} label="Room" variant="outlined" onChange={(e) => { setToken(e.target.value.replace(/[^A-z]/g, '')) }} />
          <Box mt={2}>
            <Button fullWidth sx={{ height: 60 }} variant="contained" color="primary" disabled={token.length === 0} onClick={handleJoin} endIcon={<SendIcon />}>
              Play
            </Button>
            <Box mt={1} />
            <Button fullWidth variant="contained" color="secondary" onClick={() => setOpenSearch(true)} endIcon={<SearchIcon />}>Find Rooms </Button>
          </Box>
        </ Box>
      </Box>
    </Box>
  );
}
