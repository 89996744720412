import axios from 'axios';
import socket from './socket';

export function isDev() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

const BASE_URL = isDev() ? "http://localhost:5000/api" : "/api";
const GET_API_CACHE = new Map();

export async function getApi(url, params = {}) {
    const response = await axios.get(`${BASE_URL}${url}`,
        { params: params, headers: { 'token': localStorage.getItem('token') } });
    if (response.data.success === false) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function postApi(url, data) {
    const response = await axios.post(`${BASE_URL}${url}`, data, { headers: { 'token': localStorage.getItem('token') } });
    if (response.data.success === false) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function deleteApi(url) {
    const response = await axios.delete(`${BASE_URL}${url}`, { headers: { 'token': localStorage.getItem('token') } });
    if (response.data.success === false) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function putApi(url, data) {
    const response = await axios.put(`${BASE_URL}${url}`, data, { headers: { 'token': localStorage.getItem('token') } });
    if (response.data.success === false) {
        throw new Error(response.data.message);
    }
    return response.data;
}

export async function getApiCached(url, force = false) {
    if (!force && GET_API_CACHE.has(url)) {
        return GET_API_CACHE.get(url);
    }
    const response = await getApi(url);
    GET_API_CACHE.set(url, response);
    return response;
}

export const User = {
    isLoggedIn: async function () {
        if (!localStorage.getItem('token')) {
            return false;
        }
        try {
            await getApiCached('/user');
            return true;
        } catch {
            return false;
        }
    },
    info: async function (force = false) {
        return await getApiCached('/user', force);
    },
    login: async function (mail, password) {
        const res = await postApi('/login', { mail, password });
        localStorage.setItem('token', res.token);
        this.info(true);
        socket.disconnect();
        socket.auth = { token: res.token, room_token: undefined };
        return true;
    },
    signup: async function (mail, name, password) {
        const res = await postApi('/signup', { mail, name, password });
        localStorage.setItem('token', res.token);
        this.info(true);
        socket.disconnect();
        socket.auth = { token: res.token, room_token: undefined };
        return true;
    },
    logout: function () {
        socket.disconnect();
        socket.auth = { token: undefined, room_token: undefined };
        localStorage.removeItem('token');
        window.location = '/';
    },
    get: async function (id, force = false) {
        return await getApiCached(`/user/${id}`, force);
    },

}

export const Room = {
    info: async function (token) {
        return await getApi(`/room/${token}`);
    },
    update: async function (token, data) {
        return await putApi(`/room/${token}`, data);
    },
    search: async function (dictionary, gameType) {
        return await getApi("/search", { dictionary, gameType });
    }
}
