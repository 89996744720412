import { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Box, Modal, LinearProgress, Chip, Typography } from '@mui/material';
import { Room } from '../utils/query';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { md: '500px', xs: '90%' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 2,
    borderRadius: 2,
    borderColor: '#9AC303'
};

function Choices({ color, choices, value, onChange }) {
    return (
        <Box display="flex" gap="1em">
            {choices.map(([val, txt]) =>
                <Chip
                    key={val}
                    size="small"
                    label={txt}
                    clickable
                    color={color}
                    variant={value === val ? "contained" : "outlined"}
                    onClick={() => onChange(value === val ? undefined : val)}
                    sx={{ flexGrow: 1, padding: "0.5em", margin: "0.5em" }}
                />
            )}
        </Box>
    );
}

export default function RoomSearch({ open, handleClose }) {
    const navigate = useNavigate();
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [gameType, setGameType] = useState(undefined);
    const [dictionary, setDictionary] = useState(undefined);

    useEffect(() => {
        let interval;
        setLoading(true);
        if (open) {
            Room.search(dictionary, gameType).then((rooms) => {
                setRooms(rooms);
                setLoading(false);
            })
            interval = setInterval(() => {
                Room.search(dictionary, gameType).then((rooms) => {
                    setRooms(rooms);
                    setLoading(false);
                })
            }, 5000);
        }
        return () => {
            interval && clearInterval(interval);
        }
    }, [open, gameType, dictionary])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Paper sx={{ minHeight: "180px", width: '100%' }}>
                    <TableContainer sx={{ maxHeight: "440px", borderTopLeftRadius: 6, borderTopRightRadius: 6 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={2}>
                                        Room
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                        Game
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                        Lang
                                    </TableCell>
                                    <TableCell align="center" colSpan={1}>
                                        /
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rooms
                                    .map((room) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={room.token} sx={{ cursor: "pointer" }} onClick={() => navigate(`/room/${room.token}`)}>
                                                <TableCell align="center" key={room.token} colSpan={2}>
                                                    {room.token}
                                                </TableCell>
                                                <TableCell align="center" key={room.gameType} colSpan={1}>
                                                    {room.gameType === "turn" ? "🔃" : "⚔️"}
                                                </TableCell>
                                                <TableCell align="center" key={`${room.token}-dictionary`} colSpan={1}>
                                                    {room.dictionary.includes("italian") && "🇮🇹"}
                                                    {room.dictionary.includes("english") && "🇬🇧"}
                                                </TableCell>
                                                <TableCell align="center" key={`${room.token}-players`} colSpan={1}>
                                                    {room.players}/{room.maxUsers}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!loading && rooms.length === 0 && <Typography textAlign="center" p={1}>There are no public rooms. Go make a new one!</Typography>}
                    {loading && <LinearProgress sx={{ mb: 1 }} />}
                    <Box width="100%" bottom={0}>
                        <Choices
                            color="primary"
                            choices={[["turn", "Turn by Turn"], ["ffa", "Free for All"]]}
                            value={gameType}
                            onChange={setGameType} />
                        <Choices
                            color="secondary"
                            choices={[["english", "English"], ["italian", "Italian"]]}
                            value={dictionary}
                            onChange={setDictionary} />
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}
