const letter_colors = {
    light: {
        0: "#787c7e",
        1: "#6aaa64",
        2: "#c9b458",
        default: "#d3d6da",
    },
    dark: {
        0: "#3a3a3c",
        1: "#538d4e",
        2: "#b59f3b",
        default: "#f0f0f0",
    },
};


export function getLetterColor(res, theme = "light") {
    // 0 = Grey
    // 1 = Green
    // 2 = Yellow
    // default = not chosen - light gray/white
    return letter_colors[theme][res] || letter_colors[theme]["default"];
}
