import Home from './views/Home';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Signup from './views/Signup';
import Login from './views/Login';
import RoomView from './views/Room';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect, useMemo } from 'react';
import { useNavigate  } from 'react-router-dom';

function NotFound() {
    let navigate = useNavigate();
    useEffect(() => {
        navigate("/");
    });
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home></Home>,
    },
    {
        path: "/signup",
        element: <Signup></Signup>,
    },
    {
        path: "/login",
        element: <Login></Login>,
    },
    {
        path: "/room/:token",
        element: <RoomView></RoomView>,
    },
    {
        path: "*",
        element: <NotFound></NotFound>,
    },
]);

export default function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = useMemo(
        () =>
            createTheme({
                '@global': {
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    }},
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                  primary: {
                    main: '#9AC303',
                  },
                  secondary: {
                    main: '#f2c513',
                  },
                },
                  typography: {
                    fontFamily: 'Lato',
                  },
            }),
        [prefersDarkMode],
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider router={router} />
        </ThemeProvider>
    );
}
