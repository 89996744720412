import { Box } from "@mui/material";
import { zip } from "../utils/functional";
import { memo } from "react";
import { getLetterColor } from "../themes/theme";
import { useTheme } from '@mui/material/styles';
import '../styles/Keyboard.css';

const Keyboard = memo(function Keyboard({ actions }) {
    const theme = useTheme().palette.mode;
    const marked_letters = new Map();
    actions.map((a) => zip(Array.from(a.word), a.results)).flat().forEach(([letter, res]) => {
        const current_val = marked_letters.get(letter.toUpperCase());
        if (current_val === undefined || current_val === 0 || (current_val === 2 && res === 1)) {
            marked_letters.set(letter.toUpperCase(), res);
        }
    });
    const keyb_rows = [
        ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
        ["", "A", "S", "D", "F", "G", "H", "J", "K", "L", ""],
        ["BACKSPACE", "Z", "X", "C", "V", "B", "N", "M", "ENTER"]];

    function sendKey(key) {
        var e = new KeyboardEvent('keydown', { 'key': key });
        window.dispatchEvent(e);
    }
    return (
        <Box display="flex" flexDirection="column">
            {keyb_rows.map((row, i) => {
                return (
                    <Box key={i} display="flex">
                        {row.map((key, i) => {
                            let dipslayName;
                            if (key === 'BACKSPACE'){
                                dipslayName = ' DEL ';
                            } else {
                                dipslayName = key;
                            }
                            return (
                                <button key={i} className={'keyboard-key'} onClick={() => sendKey(key)}
                                    style={{
                                        color: marked_letters.has(key) ? 'white' : 'black',
                                        backgroundColor: getLetterColor(marked_letters.get(key), theme),
                                    }}>{dipslayName}</button>
                            );
                        })}
                    </Box>
                );
            })}
        </Box>
    );
});

export default Keyboard;
