import { Snackbar, Alert } from '@mui/material';

export default function ErrorSnackbar({ text, openAlert, handleCloseAlert }) {    
    return (
        <Snackbar 
          open={openAlert} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseAlert}>
          <Alert
            severity="error"
            icon={false}
            variant="filled"
            sx={{ width: '100%' }}
            >
            {text}
            </Alert>
        </Snackbar>
    );
}