import { useState, useEffect, useRef } from "react";
import socket from "../utils/socket";
import Keyboard from "./Keyboard";
import { Box } from "@mui/material";
import { User } from "../utils/query";
import WordLine from "./WordLine";
import usePrevious from "../utils/usePrevious";
import ErrorSnackbar from "./ErrorSnackbar";
import Counter from "./Counter";
import '../styles/WordLine.css';

function parseActions(actions) {
    const arrayActions = Object.values(actions);
    if (arrayActions.length === 0) return [];
    // [ {word: "word", results: [1, 0, 1, 1]}, {...} ]
    // [ {word: "word", results: [1, 0, 1, 1]} ]
    let orderedActions = [];
    const numPlayers = arrayActions.length;
    let i = 0;
    for (; ;) {
        for (let j = 0; j < numPlayers; j++) {
            if (arrayActions[j].length <= i) return orderedActions;
            orderedActions.push(arrayActions[j][i]);
        }
        i++;
    }
}

export default function TurnGame({ wordLength }) {
    const [myTurn, setMyTurn] = useState(false);
    const [canType, setCanType] = useState(false);
    const wordRef = useRef('');
    const [word, setWord] = useState(wordRef.current);
    const prevWord = usePrevious(word);
    const [actions, setActions] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [wordNotInDict, setWordNotInDict] = useState(false);
    const [turnLength, setTurnLength] = useState(0);
    const [endTurnDate, setEndTurnDate] = useState(Date.now());
    const [timeDiff, setTimeDiff] = useState(0);
    const actionBox = useRef(null);

    function handleCloseAlert() { setOpenAlert(false); };

    useEffect(() => {
        socket.emit('getGame', Date.now());

        async function onGame(game) {
            setActions(game.actions);
            setTurnLength(game.timer);
            setEndTurnDate(game.endTurn);
            setTimeDiff(game.timeDiff);
        }

        async function onActionResponse(response) {
            if (response === false) {
                //alert("This word is not in the dictionary");
                setOpenAlert(true);
                setWordNotInDict(true);
                setTimeout(() => {
                    setWordNotInDict(false);
                }, 1000);
                setCanType(true);
                return;
            }
            wordRef.current = '';
            setWord(wordRef.current);
            setActions((prev) => {
                return { ...prev, [response.player]: [...prev[response.player], response.action] }
            });
            setEndTurnDate(response.endTurn);
        }
        function onReconnect() {
            socket.emit('getGame', Date.now());
        }

        socket.on('reconnect', onReconnect);
        socket.on('actionResponse', onActionResponse);
        socket.on('game', onGame);
        return () => {
            socket.off('reconnect', onReconnect);
            socket.off('actionResponse', onActionResponse);
            socket.off('game', onGame);
        }
    }, []);

    useEffect(() => {
        async function checkTurn() {
            const arrayActions = Object.entries(actions);
            if (arrayActions.length === 0) return;
            const playerWithTurn = arrayActions.sort((a, b) => a[1].length - b[1].length)[0][0];
            const myTurn = playerWithTurn === (await User.info()).id;
            setMyTurn(myTurn);
            setCanType(myTurn);
        }
        checkTurn();
    }, [actions]);

    useEffect(() => {
        function onType(e) {
            if (!canType || !myTurn) {
                return;
            }
            // Check if control keys are pressed
            if (e.ctrlKey || e.altKey || e.metaKey) return;
            if (document.activeElement.tagName === 'INPUT') return;

            const key = e.key.toUpperCase();
            switch (key) {
                case 'ENTER':
                    if (wordRef.current.length !== wordLength) break;
                    socket.emit('action', wordRef.current.toLowerCase());
                    setCanType(false);
                    break;
                case 'BACKSPACE':
                    wordRef.current = wordRef.current.slice(0, -1);
                    setWord(wordRef.current);
                    break;
                default:
                    if (key.length !== 1 || !key.match(/[A-Z]/)) break;
                    if (wordRef.current.length >= wordLength) break;
                    wordRef.current += key;
                    setWord(wordRef.current);
            }
        }

        window.addEventListener('keydown', onType);
        return () => {
            window.removeEventListener('keydown', onType);
        }
    }, [myTurn, canType, wordLength]);

    useEffect(() => {
        if (!actionBox.current) return;
        actionBox.current.scroll({
            top: actionBox.current.scrollHeight,
            behavior: 'smooth'
        });
    }, [canType, word, actions, myTurn]);
    return (
        <Box maxWidth={550} flexGrow={1}>
            <ErrorSnackbar text="Word not found" openAlert={openAlert} handleCloseAlert={handleCloseAlert} />
            {turnLength !== 0 && <Counter endTurnDate={endTurnDate} turnLength={turnLength} timeDiff={timeDiff}></Counter>}
            <Box sx={{ height: "calc(99dvh - 200px)" }} display="flex" flexDirection="column" alignContent={"center"}>
                <Box className="word-box" ref={actionBox} p="20px" flexGrow={1} sx={{
                    overflowY: "scroll",
                    scrollbarWidth:"none",
                    '&::-webkit-scrollbar':{
                        display:'none',
                    }
                }}>
                    {parseActions(actions).filter((a) => a.word[0] !== "-").map((action, i, arr) =>
                        <WordLine key={i} word={action.word.toUpperCase()} results={action.results} isLast={i === arr.length - 1} />
                    )}
                    <WordLine sx={{ border: "2px solid", borderRadius: "8px", borderColor: myTurn ? "aqua" : "red", width: "100%" }}
                        wordNotInDict={wordNotInDict} currentlyTyping={true} prevWord={prevWord}
                        word={word.padEnd(wordLength)} />
                </Box>
            </Box>
            <Keyboard actions={parseActions(actions)} />
        </Box>
    );
}
