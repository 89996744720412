import { Box } from '@mui/material';
import { zip } from '../utils/functional';
import Letter from './Letter';
import '../styles/WordLine.css';

export default function WordLine({ word, results, currentlyTyping = false, prevWord = '', isLast = false, wordNotInDict = false, sx = {} }) {
    if (!results) {
        results = Array.from({ length: word.length }).map(() => -1);
    }

    let letterToPop = 0;
    if (currentlyTyping) {
        const goingBackwords = word.trim().length < prevWord.length;
        if (goingBackwords) {
            letterToPop = word.trim().length;
        } else {
            letterToPop = word.trim().length - 1;
        }
    }
    const wordChanged = prevWord !== word;
    word = Array.from(word);
    return (
        <Box className={wordNotInDict ? "word-animation word-line" : "word-line"}
            sx={{ gridTemplateColumns: `repeat(${word.length}, 1fr)`, ...sx }}>
            {zip(word, results).map(([letter, res], i) =>
                <Letter
                    key={i}
                    animationDelay={currentlyTyping ? 0 : (i * 300)}
                    popIn={currentlyTyping && wordChanged && i === letterToPop}
                    letter={letter}
                    flip={isLast}
                    res={res} />
            )}
        </Box>
    )
}