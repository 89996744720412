import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InputLabel, Chip, Button, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import socket from '../utils/socket.js'
import { Room, User } from '../utils/query.js';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import '../styles/RoomSettings.css';

function Choices({ icon, text, description, choices, value, onChange, disabled, sx = {} }) {
  return (
    <Box sx={sx}>
      <Typography variant="subtitle1" >
        {icon} {text}
      </Typography>
      <Typography variant="body2">
        {description}
      </Typography>
      <Box display="flex" gap="1em">
        {choices.map(([val, txt]) =>
          <Chip disabled={disabled}
            key={val}
            size="small"
            label={txt}
            clickable={!disabled}
            color="primary"
            variant={value === val ? "contained" : "outlined"}
            onClick={() => onChange(val)}
            sx={{ flexGrow: 1, padding: "1em" }}
          />
        )}
      </Box>
    </Box>
  );
}


function NumberSelector({ min, max, value, onChange, disabled, sx = {} }) {
  const onChangeChecked = (val) => {
    if (val === "") {
      onChange(val);
      return;
    }
    if (isNaN(val)) return;
    if (val < min || val > max) return;
    onChange(val);
  }
  return (
    <Box display="flex" gap="2em" sx={sx}>
      <Button size="small" disabled={disabled} variant="contained" sx={{ borderRadius: 100, minWidth: "30px", width: "30px", height: "30px", lineHeight: "25px" }} onClick={() => onChangeChecked(value - 1)}>-</Button>
      <TextField size='small' disabled={disabled} sx={{ width: "120px" }} className="hideArrows" value={value} onChange={(e) => { onChangeChecked(e.target.value) }} />
      <Button size="small" disabled={disabled} variant="contained" sx={{ borderRadius: 100, minWidth: "30px", width: "30px", height: "30px", lineHeight: "25px" }} onClick={() => onChangeChecked(value + 1)}>+</Button>
    </Box>
  );
}
export default function RoomSettings({ inGame }) {

  const { token } = useParams();

  const [game, setGame] = useState('');
  const [dict, setDict] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const maxUsersRef = useRef(2);
  const [maxUsers, setMaxUsers] = useState(maxUsersRef.current);
  const timerRef = useRef(0);
  const [timer, setTimer] = useState(timerRef.current);
  const maxGuessesRef = useRef(8);
  const [maxGuesses, setMaxGuesses] = useState(maxGuessesRef.current);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    socket.emit("getSettings");

    async function onSettings(room) {
      setIsOwner(room.owner === (await User.info()).id);
      setGame(room.gameType);
      setIsPublic(room.public);
      setDict(room.dictionary);
      setMaxUsers(room.maxUsers);
      setTimer(room.timer);
      setMaxGuesses(room.maxGuesses);
      setIsUpdated(true);
    };

    socket.on('settings', onSettings);
    return () => {
      socket.off('settings', onSettings);
    }
  }, []);

  useEffect(() => {
    if (!hasChanged) return;
    setIsUpdated(false);
    setHasChanged(false);
    Room.update(token, {
      gameType: game,
      isPublic: isPublic,
      dictionary: dict,
      maxUsers: parseInt(maxUsers) || 2,
      timer: parseInt(timer) || 0,
      maxGuesses: isNaN(parseInt(maxGuesses)) ? 8 : parseInt(maxGuesses)
    });
  }, [hasChanged, token, game, isPublic, maxUsers, dict, maxGuesses, timer]);

  return (
    <Box>
      <Typography variant="h5" >
        Room Settings
      </Typography>
      <Typography display="inline" variant="subtitle1">Name: {token}</Typography>
      <IconButton aria-label="Copy URL" onClick={() => navigator.clipboard.writeText(window.location)}>
        <ContentCopyIcon />
      </IconButton>
      <Choices icon="🎮" text="Game Type" value={game} disabled={!isOwner || inGame} sx={{ mt: 1 }}
        description="Choose the game type"
        choices={[["turn", "Turn by Turn"], ["ffa", "Free for All"]]}
        onChange={(val) => {
          setGame(val);
          setHasChanged(true);
        }} />
      <Choices icon="📚" text="Dictionary" value={dict} disabled={!isOwner || inGame} sx={{ mt: 1 }}
        description="Choose the language of the words"
        choices={[["english", "English"], ["italian", "Italian"]]}
        onChange={(val) => {
          setDict(val);
          setHasChanged(true);
        }} />
      <Choices icon="👁️" text="Visibility" value={isPublic} disabled={!isOwner || inGame} sx={{ mt: 1 }}
        description="Make the room be accessible by everyone or only by invite"
        choices={[[true, "Public"], [false, "Private"]]}
        onChange={(val) => {
          setIsPublic(val);
          setHasChanged(true);
        }} />
      <Box display="flex" justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
        <InputLabel sx={{ mt: 1 }}>Number of players</InputLabel>
        <NumberSelector min={1} max={100} value={maxUsers} disabled={!isOwner || inGame} onChange={(val) => {
          maxUsersRef.current = val;
          setIsUpdated(false);
          setMaxUsers(val);
          setTimeout(() => {
            if (val !== maxUsersRef.current || isNaN(parseInt(val))) return;
            setHasChanged(true);
          }, 300);
        }} />
        {game === "turn" ?
          <>
            <InputLabel sx={{ mt: 1 }}>Turn Duration (0 for infinte)</InputLabel>
            <NumberSelector min={0} max={360} value={timer} disabled={!isOwner || inGame} onChange={(val) => {
              timerRef.current = val;
              setIsUpdated(false);
              setTimer(val);
              setTimeout(() => {
                if (val !== timerRef.current || isNaN(parseInt(val))) return;
                setHasChanged(true);
              }, 300);
            }} />
          </>
          :
          <>
            <InputLabel sx={{ mt: 1 }}>Max Guesses (0 = unlimited)</InputLabel>
            <NumberSelector min={0} max={100} value={maxGuesses} disabled={!isOwner || inGame} onChange={(val) => {
              maxGuessesRef.current = val;
              setIsUpdated(false);
              setMaxGuesses(val);
              setTimeout(() => {
                if (val !== maxGuessesRef.current || isNaN(parseInt(val))) return;
                setHasChanged(true);
              }, 300);
            }} />
          </>
        }
        <Button sx={{ mt: 1 }} disabled={!isOwner || !isUpdated} variant='outlined' onClick={() => socket.emit("start")}>Start!</Button>
      </Box>
    </Box>
  );
}
