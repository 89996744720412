import React, { useEffect, useState } from 'react';
import { User } from '../utils/query.js';
import { useParams } from 'react-router-dom'
import socket from '../utils/socket.js';
import RoomSettings from '../components/RoomSettings.js';
import PlayersList from '../components/PlayersList.js';
import { useNavigate } from 'react-router-dom';
import TurnGame from '../components/TurnGame.js';
import { Box, Button } from '@mui/material';
import Chat from '../components/Chat.js';
import FFAGame from '../components/FFAGame.js';
import CustomDialog from '../components/CustomDialog.js';
import ErrorSnackbar from '../components/ErrorSnackbar.js';

export default function RoomView() {
  let navigate = useNavigate();
  let { token } = useParams();

  const [openDialog, setOpenDialog] = useState(false);
  let [players, setPlayers] = useState([]);
  let [owner, setOwner] = useState('');
  let [isOwner, setIsOwner] = useState(false);
  let [inGame, setInGame] = useState(false);
  let [gameType, setGameType] = useState('turn');
  let [errorMessage, setErrorMessage] = useState('');
  let [title, setTitle] = useState('');
  let [description, setDescription] = useState('');
  let [buttonText, setButtonText] = useState('');

  const handleCloseAlert = () => {
    setErrorMessage("");
    navigate("/");
  };

  useEffect(() => {

    User.isLoggedIn().then((res) => {
      if (!res) navigate("/login");
    });

    socket.auth.room_token = token;
    socket.connect();
    function onError(err) {
      setErrorMessage(err);
      socket.disconnect();
    }

    async function onJoined(data) {
      setOwner(data.owner);
      setInGame(data.inGame);
      // TODO: data.gameType non e' aggiornato risepetto alle impostazioni
      setGameType(data.gameType);
      setIsOwner((await User.info()).id === data.owner);
      const players = await Promise.all(data.players.map((playerId) =>
        User.get(playerId)
      ));
      setPlayers(players);
    }

    function onKick() {
      setErrorMessage("Boss kicked your ass");
      socket.disconnect();
    }

    function onStart(gameType) {
      setGameType(gameType);
      setInGame(true);
    }


    async function onWin(info) {
      setInGame(false);
      let playerWinner = info.player === undefined ? { name: "Nobody" } : await User.get(info.player);
      setTitle(playerWinner.name + " has won the game!");
      setDescription("The word was: " + info.word);
      ((await User.info()).id === playerWinner.id) ? setButtonText('Yay') : setButtonText('F***');
      setOpenDialog(true);

    }
    socket.on('error', onError);
    socket.on('kick', onKick);
    socket.on('joined', onJoined);
    socket.on('start', onStart);
    socket.on('win', onWin);
    return () => {
      socket.off('error', onError);
      socket.off('kick', onKick);
      socket.off('joined', onJoined);
      socket.off('win', onWin);
      socket.off('start', onStart);
      socket.auth.room_token = undefined;
      socket.disconnect();
    };
  }, [navigate, token]);

  return (
    <Box>
      <CustomDialog description={description} buttonText={buttonText} title={title} open={openDialog} handleClose={() => setOpenDialog(false)} />
      <ErrorSnackbar text={errorMessage} openAlert={errorMessage !== ""} handleCloseAlert={handleCloseAlert} />
      <Box width="100%" display="flex" justifyItems="center" justifyContent="center">
        {!inGame && <RoomSettings inGame={inGame} />}
        {inGame ? gameType === "turn" ? <TurnGame wordLength={5} /> : <FFAGame wordLength={5} /> : <></>}
      </Box>
      <br />
      <Box width="100%" display="flex" justifyItems="center" justifyContent="center">
        <Box display="flex" flexDirection={"column"} justifyContent={"center"} maxWidth={550} flexGrow={1} sx={{ border: 2, borderRadius: 2, borderColor: '#9AC303', p: 1 }}>
          <PlayersList players={players} owner={owner} isOwner={isOwner} />
          {inGame && <Button onClick={() => socket.emit("endGame")} disabled={!isOwner} color='error'>End game</Button>}
          <Chat />
        </Box>
      </Box>
    </Box >
  )
}
