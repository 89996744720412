import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { User } from '../utils/query.js';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { TextField, Button, Avatar, Link } from '@mui/material';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import {Link as RouterLink} from 'react-router-dom';

export default function Login() {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  async function login(e) {
    e.preventDefault();
    try {
      await User.login(mail, password);
      navigate('/');
    } catch (e) {
      setError(e.message);
    }
  }
  return (
    <Box 
        sx={{
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
          }}     
    >
    <ErrorSnackbar text={error} openAlert={error !== ''} handleCloseAlert={() => setError('')} />
    <Box component="form" onSubmit={login} sx={{ 
      display: 'flex',
      flexDirection:'column',
      width: { sm: '60vw', xs: '90vw', lg: '35vw'},
      textAlign: 'center',
      pt: 6,
      pb:6,
      pl:'5vw',
      pr:'5vw',
      border: 2,
      borderRadius: 2,
      borderColor: '#9AC303'
    }}>
      <Avatar src="logo-1024.png" sx={{alignSelf:'center', mb:2}}/>
      <Typography component={'h1'} variant="h5" >
        Login to play
          Wordy
      </Typography>
      <TextField id="mail" type="email" label="Email" sx={{
          mt:2
        }} onChange={e => setMail(e.target.value)} variant="outlined" value={mail} />
      <TextField id="password" type="password" label="Password" sx={{
          mt:2
        }} onChange={e => setPassword(e.target.value)} variant="outlined" value={password} />
      <Button variant="contained" type="submit" color='primary'sx={{
          mt:2
        }} >Login</Button>
      <Typography mt={2} >Don't have an account?</Typography> 
      <Link component={RouterLink} to="/signup" color='primary' underline="none" variant="body2">Signup</Link>
    </Box>
    </Box>
  );
}
