import { Paper, Button, Table } from "@mui/material";
import socket from "../utils/socket";
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function PlayersList({ players, owner, isOwner }) {
    async function kickPlayer(player) {
        await socket.emit('kick', player.id);
    }
    return (
        <Paper sx={{ maxHeight: 440 }}>
            <TableContainer >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={3}>
                                Players
                            </TableCell>
                            {isOwner && <TableCell align="center" colSpan={1}>
                                Action
                            </TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {players.map((player) =>
                            <TableRow hover role="checkbox" tabIndex={-1} key={player.id}>
                                <TableCell align="center" colSpan={3}>{player.name} {player.id === owner && "(👑)"}</TableCell>
                                {isOwner && <TableCell align="center" colSpan={1}>{player.id !== owner &&
                                    <Button size="small" variant="contained" color="error" onClick={() => kickPlayer(player)} >Kick</Button>}</TableCell>}
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}