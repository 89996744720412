import { useState, useEffect, useRef } from "react";
import socket from "../utils/socket";
import Keyboard from "./Keyboard";
import { Box } from "@mui/material";
import WordLine from "./WordLine";
import ErrorSnackbar from "./ErrorSnackbar";
import usePrevious from "../utils/usePrevious";

export default function FFAGame({ wordLength }) {
    const wordRef = useRef('');
    const [word, setWord] = useState(wordRef.current);
    const prevWord = usePrevious(word);
    const [actions, setActions] = useState([]);
    const [canType, setCanType] = useState(true);
    const [text, setText] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [wordNotInDict, setWordNotInDict] = useState(false);
    const [maxGuesses, setMaxGuesses] = useState(8);
    const actionBox = useRef(null);

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    useEffect(() => {
        socket.emit('getGame');

        async function onGame(game) {
            if (!game.actions) {
                setText('You are not part of the game');
                setOpenAlert(true);
                return;
            }
            setMaxGuesses(game.maxGuesses);
            setActions(game.actions);
        }

        async function onActionResponse(response) {
            setCanType(true);
            if (response === false) {
                setText('This word is not in the dictionary');
                setWordNotInDict(true);
                setTimeout(() => {
                    setWordNotInDict(false);
                }, 1000);
                setOpenAlert(true);
                return;
            }
            wordRef.current = '';
            setWord(wordRef.current);
            setActions((prev) => {
                return [...prev, response.action]
            });
        }

        function onReconnect() {
            socket.emit('getGame');
        }

        socket.on('reconnect', onReconnect);
        socket.on('actionResponse', onActionResponse);
        socket.on('game', onGame);
        return () => {
            socket.off('reconnect', onReconnect);
            socket.off('actionResponse', onActionResponse);
            socket.off('game', onGame);
        }
    }, []);

    useEffect(() => {
        function onType(e) {
            if (!canType) {
                return;
            }
            // Check if control keys are pressed
            if (e.ctrlKey || e.altKey || e.metaKey) return;
            if (document.activeElement.tagName === 'INPUT') return;

            const key = e.key.toUpperCase();
            switch (key) {
                case 'ENTER':
                    if (wordRef.current.length !== wordLength) break;
                    socket.emit('action', wordRef.current.toLowerCase());
                    setCanType(false);
                    break;
                case 'BACKSPACE':
                    wordRef.current = wordRef.current.slice(0, -1);
                    setWord(wordRef.current);
                    break;
                default:
                    if (key.length !== 1 || !key.match(/[A-Z]/)) break;
                    if (wordRef.current.length >= wordLength) break;
                    wordRef.current += key;
                    setWord(wordRef.current);
            }
        }

        window.addEventListener('keydown', onType);
        return () => {
            window.removeEventListener('keydown', onType);
        }
    }, [canType, wordLength]);

    useEffect(() => {
        if (!actionBox.current) return;
        if (maxGuesses === 0) {
            actionBox.current.scroll({
                top: actionBox.current.scrollHeight,
                behaviour: 'smooth'
            });
        } else {
            const lineHeight = (actionBox.current.scrollHeight / maxGuesses)
            const height = lineHeight * (actions.length - 1);
            actionBox.current.scroll({ top: height, behaviour: 'smooth' });
        }
    }, [canType, word, actions, maxGuesses]);

    return (
        <Box maxWidth={550} flexGrow={1}>
            <ErrorSnackbar text={text} openAlert={openAlert} handleCloseAlert={handleCloseAlert} />
            <Box sx={{ height: "calc(99dvh - 200px)" }} display="flex" flexDirection="column" alignContent={"center"}>
                <Box ref={actionBox} flexGrow={1} p="20px" sx={{
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                }}>
                    {actions.map((action, i, arr) =>
                        <WordLine key={i} word={action.word.toUpperCase()} results={action.results} isLast={i === arr.length - 1} />
                    )}
                    {(maxGuesses === 0 || (actions.length < maxGuesses)) && <WordLine wordNotInDict={wordNotInDict} currentlyTyping={true} prevWord={prevWord} word={word.padEnd(wordLength)} />}
                    {Array.from({ length: (maxGuesses - actions.length - 1) }).map((_, i) =>
                        <WordLine key={i} word={"".padEnd(wordLength)} />
                    )}
                </Box>
            </Box>
            <Keyboard actions={actions} />
        </Box>
    );
}
