import React, { useState } from 'react';
import { User } from '../utils/query.js';
import { Box } from '@mui/system';
import { Typography, Avatar, TextField, Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import { Link as RouterLink } from 'react-router-dom';

export default function Signup() {
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate('');

  async function signup(e) {
    e.preventDefault();
    try {
      await User.signup(mail, name, password);
      navigate("/");
    } catch (e) {
      setError(e.message);
    }
  }
  return (
    <Box 
        sx={{
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
          }}     
    >
    <ErrorSnackbar text={error} openAlert={error !== ''} handleCloseAlert={() => setError('')} />
    <Box component="form" onSubmit={signup} sx={{ 
      display: 'flex',
      flexDirection:'column',
      width: { sm: '60vw', xs: '90vw', lg: '35vw'},      textAlign: 'center',
      pt: 6,
      pb:6,
      pl:'5vw',
      pr:'5vw',
      border: 2,
      borderRadius: 2,
      borderColor: '#9AC303'
    }}>
      <Avatar src="logo-1024.png" sx={{alignSelf:'center', mb:2}}/>
      <Typography component={'h1'} variant="h5" >
        Signup to play
      </Typography>
      <TextField required fullWidth sx={{mt: 2}} id="mail" type="email" label="Email" onChange={e => setMail(e.target.value)} variant="outlined" value={mail} />
      <TextField required fullWidth sx={{mt: 2}} id="name" type="text" label="Username" onChange={e => setName(e.target.value)} variant="outlined" value={name} />
      <TextField required fullWidth sx={{mt: 2}} id="password" type="password" label="Password" onChange={e => setPassword(e.target.value)} variant="outlined" value={password} />
      <Button variant="contained" type="submit" color='primary'sx={{
          mt:2
        }} >Signup</Button>
      <Typography mt={2}>Already have an account?</Typography> 
      <Link component={RouterLink} to="/login" color='primary' underline="none" variant="body2">Login</Link>
    </Box>
    </Box>
  );
}
