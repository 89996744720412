import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";

export default function Counter({ turnLength, endTurnDate, timeDiff = 0 }) {
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(((endTurnDate - (Date.now() + timeDiff)) / 10) / turnLength)
        }, 1000);
        return () => clearInterval(interval);
    }, [turnLength, endTurnDate, timeDiff]);
    return (
        <LinearProgress variant="determinate" value={progress} />
    );
}