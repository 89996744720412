import { getLetterColor } from '../themes/theme';
import { Box } from '@mui/material';
import '../styles/Letter.css';
import { useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

export default function Letter({ letter, res, popIn = false, flip = false, animationDelay = 0 }) {
    const theme = useTheme().palette.mode;
    const boxRef = useRef(null);
    let classes = ["letter"];
    if (flip) {
        classes.push("flip");
    }
    if (popIn) {
        classes.push("pop");
    }

    useEffect(() => {
        boxRef.current.classList.add(...classes);
        if (classes.includes("pop")) {
            setTimeout(() => {
                if (!boxRef.current) return;
                boxRef.current.classList.remove("pop");
            }, 250);
        } else if (classes.includes("flip")) {
            setTimeout(() => {
                if (!boxRef.current) return;
                boxRef.current.style.color = "white";
            }, animationDelay + 400);
        }
    });

    useEffect(() => {
        setTimeout(() => {
            if (!boxRef.current) return;
            boxRef.current.style.backgroundColor = getLetterColor(res, theme);
        }, animationDelay + 250);
    }, [animationDelay, res, theme]);

    return (
        <Box
            ref={boxRef}
            sx={{
                backgroundColor: flip ? getLetterColor(-1, theme) : getLetterColor(res, theme),
                animationDelay: `${animationDelay}ms`,
                color: flip ? "black" : (res === -1 ? "black" : "white"),
            }}
        > {letter}</Box >
    )
}