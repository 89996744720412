import { Box, Button, TextField, Typography } from "@mui/material";
import socket from "../utils/socket";
import { User } from "../utils/query";
import { useState, useEffect } from "react";
import styled from '@mui/material/styles/styled';


const MessageBubble = styled(Box)(({ theme }) => ({
    borderRadius: "1.5em",
    padding: "0.7em",
    maxWidth: "80%",
    backgroundColor: theme.palette.divider,
    mask:
        "radial-gradient(100% 100% at 1.5em 0,#0000 99%,#000 102%) 1.5em 100%/1em 1em no-repeat, linear-gradient(#000 0 0) padding-box",
}));


function Message({ player, text, right = false }) {
    return (
        <MessageBubble sx={right ? { borderBottomRightRadius: 0, placeSelf: "flex-end" } : { borderBottomLeftRadius: 0, placeSelf: "flex-start" }}>
            <Typography fontWeight="bold" align={right ? "right" : "left"}>~{player}</Typography>
            <Typography sx={{ wordBreak: "break-word"}}>{text}</Typography>
        </MessageBubble>
    )
}

export default function Chat() {
    let [chat, setChat] = useState([]);
    let [message, setMessage] = useState('');

    useEffect(() => {
        function onMessage({ playerId, message }) {
            User.get(playerId).then((res) => res.name).then((player) => {
                setChat(previous => [{ player, text: message }, ...previous]);
            });
        }
        socket.on('message', onMessage);
        return () => {
            socket.off('message', onMessage);
        };
    }, []);

    function sendMessage() {
        if (message === '') return;
        socket.emit('message', message);
        setChat(previous => [{ player: 'You', text: message, right: true }, ...previous]);
        setMessage('');
    }

    return (
        <Box>
            <Box display="flex" p="10px" flexDirection="column-reverse" gap="10px" sx={{
                overflowY: "scroll",
                height: "60vh",
                scrollbarWidth:'none',
                '*::-webkit-scrollbar': {
                    display: 'none'
                },}}>
                {chat.map((message, i) => {
                    return <Message key={i} player={message.player} text={message.text} right={message.right} />
                })}
            </Box>
            <Box display="flex" gap="10px" m="2px">
                <TextField size="small" fullWidth type="text"
                    value={message}
                    onKeyDown={(e) => e.key === "Enter" && sendMessage()}
                    onChange={(e) => setMessage(e.target.value)} />
                <Button onClick={sendMessage} variant="outlined">Send</Button>
            </Box>
        </Box>
    )
}
